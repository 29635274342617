import request from '@/lib/request';

interface Report {
  message: string;
  stack: string;
  appVersion: string;
}

class MonitoringService {
  private baseUrl = '/api/monitoring';

  sendReport(report: Report): Promise<void> {
    return request.post(`${this.baseUrl}/report`, report);
  }
}

export const monitoringService = new MonitoringService();
