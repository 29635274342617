import { Map } from 'immutable';
import { createSelector } from 'reselect';
import {
  CompanyRoles,
  currentCompanyRoleLsKey
} from '@/components/CompanyRoles/constants';
import { ModuleName } from '@/constants/module';
import { LocationObject, Nullable } from '@/types';
import {
  ContactForChannel,
  ExtendedUser,
  InviteInfo,
  PackageOptions,
  ResponsibleUser,
  User
} from './storeGetters.types';

const getUser = (state: Map<string, unknown>): Map<string, User> =>
  state.getIn(['user', 'user']);

const getInviteInfoMap = (
  state: Map<string, unknown>
): Map<string, InviteInfo> => state.getIn(['invite', 'inviteInfo']);

const getCountriesMap = (
  state: Map<string, unknown>
): Map<string, LocationObject> =>
  state.getIn(['purchaseRequests', 'countries']);

const getRegionsMap = (
  state: Map<string, unknown>
): Map<string, LocationObject> => state.getIn(['purchaseRequests', 'regions']);

const getCitiesMap = (
  state: Map<string, unknown>
): Map<string, LocationObject> => state.getIn(['purchaseRequests', 'cities']);

const getResponsibleUsersMap = (
  state: Map<string, unknown>
): Map<string, ResponsibleUser[]> =>
  state.getIn(['purchaseRequests', 'responsibles']);

const getContactsForChatMep = (
  state: Map<string, unknown>
): Map<string, ContactForChannel[]> =>
  state.getIn(['contacts', 'contactsForChannel']);

export const getExtendedUser = createSelector(
  [getUser],
  (user): ExtendedUser => {
    const plainUser = user.toJS() as User;

    const isCustomer = Boolean(plainUser.company?.roles?.customer);
    const isSupplier = Boolean(plainUser.company?.roles?.supplier);

    let currentCompanyRole: CompanyRoles;

    if (isCustomer && isSupplier) {
      const savedRole = localStorage.getItem(currentCompanyRoleLsKey);

      currentCompanyRole =
        (savedRole as Nullable<CompanyRoles>) ?? CompanyRoles.supplier;
    } else {
      currentCompanyRole =
        (
          Object.keys(plainUser.company?.roles ?? {}) as Array<CompanyRoles>
        ).find((key) => plainUser.company?.roles[key]) ?? CompanyRoles.supplier;
    }

    return {
      ...plainUser,
      isCustomer,
      isSupplier,
      currentCompanyRole
    };
  }
);

export const getBlockedFeatures = createSelector(
  [getUser],
  (user): PackageOptions[] => {
    const plainUser = user.toJS() as User;

    return plainUser.company?.blockedFeatures ?? Object.values(PackageOptions);
  }
);

export const getCountries = createSelector(
  [getCountriesMap],
  (countries): LocationObject[] => countries?.toJS()
);

export const getRegions = createSelector(
  [getRegionsMap],
  (regions): LocationObject[] => regions?.toJS()
);

export const getCities = createSelector(
  [getCitiesMap],
  (cities): LocationObject[] => cities?.toJS()
);

export const getResponsibleUsers = createSelector(
  [getResponsibleUsersMap],
  (users): ResponsibleUser[] => users?.toJS()
);

export const getContactsForChat = createSelector(
  [getContactsForChatMep],
  (contacts): ContactForChannel[] => contacts?.toJS()
);

export const getActiveModule = (state: Map<string, unknown>): ModuleName =>
  (state.get('module') as any).activeModule;

export const isPluginAmoActive = createSelector([getUser], (user): boolean => {
  const plainUser = user.toJS() as User;

  return plainUser.company?.plugins?.amo ?? false;
});

export const getInviteInfo = createSelector(
  [getInviteInfoMap],
  (inviteInfo): InviteInfo | undefined => inviteInfo?.toJS()
);
