import { DEFAULT_COUNTRY_CODE } from '@/core/constants/country';
import { UserInfoDto } from './userInfo';
import { RussianCompanyInfoDto } from './russianCompanyInfo';
import { ForeignCompanyInfoDto } from './foreignCompanyInfo';
import { ResponseInfoDto } from './responseInfo';

export class QuickResponseDto {
  constructor(props) {
    this.userInfo = new UserInfoDto(props.userInfo);
    this.companyInfo =
      props.companyInfo.countryCode === DEFAULT_COUNTRY_CODE
        ? new RussianCompanyInfoDto(props.companyInfo)
        : new ForeignCompanyInfoDto(props.companyInfo);
    this.responseInfo = new ResponseInfoDto(props.responseInfo);
    this.nonPriceCriteria = props.nonPriceCriteria;
    this.hints = props.hints;
  }
}
