import { Nullable } from '@/types';
import { isTypeof } from '@/utils/isTypeof';

export function toNumber<T>(
  value: Nullable<string | number | undefined>,
  fallback: T
): number | T {
  if (!value) {
    return fallback;
  }

  if (isTypeof(value, 'number')) {
    return value;
  }

  if (isTypeof(value, 'string')) {
    const parsedValue = +value.replace(',', '.');

    return !Number.isNaN(parsedValue) ? parsedValue : fallback;
  }

  return fallback;
}
