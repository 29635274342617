import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { Loader } from '@link/react-components';
import { ModuleName } from '@/constants/module';
import { ProtectedRoute } from '@/components/PrivateRoute';
import { useAppSections } from '@/hooks/useAppSections';
import { useSwitchModule } from '@/hooks/useSwitchModule';
import appConfig from '@/config/app';
import { useSelector } from 'react-redux';
import { getBlockedFeatures } from '@/storeGetters/storeGetters';
import { PackageOptions } from '@/storeGetters/storeGetters.types';
import { Goods } from './goods';
import { InboxOrders } from './inboxOrders';
import { OutboxOrders } from './outboxOrders';
import { OutboxOrder } from './outboxOrder';
import { IncomingOrder } from './incomingOrder';
import { Cart } from './cart';

export function MarketplacePages() {
  const { MARKETPLACE_ENABLED_FEATURE_FLAG } = appConfig;

  if (!MARKETPLACE_ENABLED_FEATURE_FLAG) {
    return <Navigate to="/" replace />;
  }

  const location = useLocation();
  const { sections } = useAppSections();
  const { activeModule, onSwitchModule } = useSwitchModule();
  const blockedFeatures = useSelector(getBlockedFeatures);

  const isMarketplaceFeatureBlocked = blockedFeatures.includes(
    PackageOptions.MARKETPLACE
  );

  if (activeModule !== ModuleName.Marketplace && !isMarketplaceFeatureBlocked) {
    onSwitchModule(ModuleName.Marketplace, { url: location.pathname });
    return <Loader />;
  }

  return (
    <Routes>
      <Route
        path="/goods"
        element={
          <ProtectedRoute isAllowed={sections.goods}>
            <Goods />
          </ProtectedRoute>
        }
      />
      <Route
        path="/orders"
        element={
          <ProtectedRoute isAllowed={sections.inboxOrders}>
            <InboxOrders />
          </ProtectedRoute>
        }
      />
      <Route
        path="/orders/:orderId"
        element={
          <ProtectedRoute isAllowed={sections.inboxOrders}>
            <IncomingOrder />
          </ProtectedRoute>
        }
      />
      <Route
        path="/my/orders"
        element={
          <ProtectedRoute isAllowed={sections.outboxOrders}>
            <OutboxOrders />
          </ProtectedRoute>
        }
      />
      <Route
        path="/my/orders/:orderId"
        element={
          <ProtectedRoute isAllowed={sections.outboxOrders}>
            <OutboxOrder />
          </ProtectedRoute>
        }
      />
      <Route
        path="/cart"
        element={
          <ProtectedRoute isAllowed={sections.cart}>
            <Cart />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
}
