import {
  useState,
  createContext,
  useMemo,
  useContext,
  PropsWithChildren,
  useCallback,
  MouseEvent
} from 'react';
import cn from 'classnames';
import { Dialog, DialogClasses } from '@mui/material';
import { Typography } from '@/components/Typography';
import { ButtonIcon } from '@/components/ButtonIcon';
import { Loader } from '@/components/Loader';
import { WithClassName } from '@/components/common.types';
import { WizardContext, WizardProps, Step } from './Wizard.types';
import styles from './Wizard.module.scss';

type CloseReason = 'escapeKeyDown' | 'backdropClick';

const Context = createContext<WizardContext>(null as unknown as WizardContext);

export function useWizard() {
  return useContext<WizardContext>(Context);
}

interface HeaderProps extends WithClassName {
  title: string;
  titleClassName?: string;
  extendedHeaderClassName?: string;
  nextKey?: string;
  previousKey?: string;
  showArrow?: boolean;
  onClose?: () => void;
}

function Header({
  title,
  children,
  nextKey = '',
  previousKey = '',
  showArrow = true,
  className,
  titleClassName,
  extendedHeaderClassName,
  onClose
}: PropsWithChildren<HeaderProps>) {
  const { previous, next } = useWizard();

  return (
    <div className={cn(styles.header, className)}>
      {showArrow && previousKey.length > 0 && (
        <ButtonIcon iconName="arrow-left" onClick={() => previous()} />
      )}
      <Typography
        className={cn(styles.headerTitle, titleClassName)}
        variant="h2">
        {title}
      </Typography>
      <div className={cn(styles.customHeader, extendedHeaderClassName)}>
        {children}
      </div>
      {showArrow && nextKey.length > 0 && (
        <ButtonIcon iconName="arrow-right" onClick={() => next()} />
      )}
      <ButtonIcon
        iconName="close"
        onClick={onClose}
        className={styles.closeButton}
      />
    </div>
  );
}

interface WizardContainerProps<TState> {
  steps: Step[];
  initialState: Partial<TState>;
  onClose?: () => void;
  contentClassName?: string;
}

interface MainContentProps extends PropsWithChildren, WithClassName {}

function MainContent({ className, children }: MainContentProps) {
  return (
    <div className={cn(styles.content, className)} data-testid="wizard-content">
      {children}
    </div>
  );
}

type SetStateCallbackType = <State>(newState: Partial<State>) => void;

function WizardContainer<TState>({
  steps,
  initialState,
  onClose,
  contentClassName
}: WizardContainerProps<TState>) {
  const [stepIndex, setStepIndex] = useState(0);
  const [state, setState] = useState<TState>({ ...initialState } as TState);
  const {
    displayName,
    mainContent: content,
    extendedHeader,
    headerClassName,
    headerTitleClassName,
    extendedHeaderClassName,
    next: nextStepIndex,
    previous: previousStepIndex,
    showArrow
  } = steps[stepIndex];

  const setStateCallback = useCallback<SetStateCallbackType>((newState) => {
    setState((prevState) => ({
      ...prevState,
      ...newState
    }));
  }, []);

  const goToCallback = useCallback(
    (key: string) => {
      const nextIndex = steps.findIndex((step) => step.key === key);
      setStepIndex(nextIndex);
    },
    [steps, setStepIndex]
  );

  const context = useMemo(
    () => ({
      previous(): void {
        const { previous } = steps[stepIndex];
        if (!steps[stepIndex].previous) return;
        const nextIndex = steps.findIndex((step) => step.key === previous);
        setStepIndex(nextIndex);
      },
      next(): void {
        const { next } = steps[stepIndex];
        if (!steps[stepIndex].next) return;
        const nextIndex = steps.findIndex((step) => step.key === next);
        setStepIndex(nextIndex);
      },
      goTo: goToCallback,
      getState<State>() {
        return state as unknown as State;
      },
      setState: setStateCallback
    }),
    [goToCallback, setStateCallback, steps, stepIndex, state]
  );

  return (
    <Context.Provider value={context}>
      <Header
        title={displayName}
        className={headerClassName}
        titleClassName={headerTitleClassName}
        extendedHeaderClassName={extendedHeaderClassName}
        nextKey={nextStepIndex}
        previousKey={previousStepIndex}
        showArrow={showArrow}
        onClose={onClose}>
        {extendedHeader}
      </Header>
      <MainContent className={contentClassName}>{content}</MainContent>
    </Context.Provider>
  );
}

export function Wizard<TState>({
  loading = false,
  className,
  onClose,
  open,
  steps,
  disableBackdropClick = false,
  initialState = {},
  wizardClassName,
  contentClassName
}: WizardProps<TState>) {
  const classes = useMemo(() => {
    const result: Partial<DialogClasses> = {};

    if (wizardClassName) {
      result.paper = wizardClassName;
    }

    return result;
  }, [wizardClassName]);

  const close = useCallback(
    (_event: MouseEvent<HTMLButtonElement>, reason?: CloseReason) => {
      if (disableBackdropClick && reason && reason === 'backdropClick') {
        return;
      }

      if (typeof onClose === 'function') {
        onClose();
      }
    },
    [onClose, disableBackdropClick]
  );
  return (
    <Dialog
      onClose={close}
      fullScreen={false}
      fullWidth={false}
      disableEscapeKeyDown={false}
      open={open}
      classes={classes}
      data-testid="wizard-modal">
      <div className={cn(styles.container, className)}>
        {loading ? (
          <Loader />
        ) : (
          <WizardContainer
            contentClassName={contentClassName}
            steps={steps}
            initialState={initialState}
            onClose={onClose}
          />
        )}
      </div>
    </Dialog>
  );
}
