import { CompanyRoles } from '@/components/CompanyRoles/constants';
import { Id, LocaleTypes, Nullable } from '@/types';

export interface QualificationRequestBadge {
  type: string;
  status: string;
  typeId: number;
  userId: number;
  companyId: number;
}

export interface User {
  id: Id;
  login: string;
  currentCompany: Id;
  company: {
    billingStatus: Nullable<unknown>;
    blockedFeatures: Array<PackageOptions>;
    primaryPartner: Nullable<Id>;
    roles: {
      customer: boolean;
      supplier: boolean;
    };
    packages: Array<{
      id?: Id;
      name: string;
      price: Nullable<number>;
      trial: boolean;
      expirationAt: Nullable<Date>;
      active: boolean;
    }>;
    plugins: {
      amo: boolean;
    };
    generalSettings: {
      approvalFlowAllowed: boolean;
    };
  };
  firstName: string;
  lastName: string;
  middleName: string;
  phoneNumber: string;
  email: string;
  avatar: string;
  new: boolean;
  language: LocaleTypes;
  type: string;
  permissions: Array<string>;
  blocked: boolean;
  isBlockedCompany: boolean;
  billingBlocked: boolean;
  isEnabledBilling: boolean;
  isPaidTariff: boolean;
  emailNotifications: {
    chat: boolean;
    purchaseRequests: boolean;
  };
  position: string;
  department: string;
  employeeId: Id;
  pushNotificationOff?: boolean;
  companyReliability: number;
  updatedAt: string;
  roles: Array<string>;
  status: Nullable<{
    value: unknown;
    notification: boolean;
  }>;
  availableCompanies: {
    countryCode: string;
    id: Id;
    inn?: Nullable<string>;
    ino?: Nullable<string>;
    name: string;
    logo: Nullable<string>;
  }[];
}

export interface ExtendedUser extends User {
  isCustomer: boolean;
  isSupplier: boolean;
  currentCompanyRole: CompanyRoles;
}

export interface ResponsibleUser {
  id: Id;
  employeeId: Id;
  firstName: string;
  lastName: string;
  nickName: string;
  avatar: Nullable<string>;
  position: Nullable<string>;
  phoneNumber: Nullable<string>;
  email: Nullable<string>;
  deletedAt: Nullable<string>;
}

export interface ContactForChannel {
  userId: Id;
  firstName: string;
  lastName: string;
  avatar: string | null;
  companyId: Id;
  companyName: string;
  employeeId: Id;
}

export enum MemberRole {
  PARTICIPANT = 'PARTICIPANT',
  EDITOR = 'EDITOR',
  ADMIN = 'ADMIN',
  READER = 'READER'
}

export interface ChatMember {
  type: string;
  employeeId: Id;
  companyId: Id;
  userName: string;
  companyName: string;
  department: string;
  avatarSrc: string;
  memberStatus: string;
  memberRole: MemberRole;
  changedMSAt: string;
  changedMRAt: string;
  isDeletedCompany: boolean;
}

export enum PackageOptions {
  REQUESTS_WITH_CONFIRMATION_CREATE = 'requests:with-confirmation:create',
  PROPOSALS_CONFIRMATION = 'proposals:confirmation',
  ROUTES_EDIT = 'routes:edit',
  REQUESTS_PRIVATE_CREATE = 'requests:private:create',
  COMPANY_RELIABILITY = 'company:reliability',
  REQUESTS_AUCTION_CREATE = 'requests:auction:create',
  REQUESTS_NON_PRICE_CRITERIA_ADD = 'requests:non-price-criteria:add',
  REQUESTS_BY_EMAIL_SEND = 'requests:by-email:send',
  REQUESTS_BY_EMAIL_CHAT = 'requests:by-email:chat',
  REQUESTS_MULTI_STAGE = 'requests:multi-stage',
  ACCREDITATION_EDIT = 'accreditation:edit',
  QUALIFICATION_EDIT = 'qualification:edit',
  NEEDS_EDIT = 'needs:edit',
  PARTNERS_IMPORT = 'partners:import',
  PARTNERS_BULK_INVITE = 'partners:bulk-invite',
  FINANCES_PRIMARY_PARTNER_EDIT = 'finances:primary-partner:edit',
  REQUESTS_REGULAR_CREATE = 'requests:regular:create',
  RESPONSES_COMPARE = 'responses:compare',
  MESSENGER = 'messenger',
  REQUEST_MANAGE_AUTOORDERS = 'requests:manage-auto-orders',
  REQUESTS_EXPORT_DOCUMENTS = 'requests:export-documents',
  VENDORS_EVALUATION = 'vendors-evaluation',
  PLUGIN_AMO = 'plugin:amo',
  SUPPLIERS_CLASSIFICATION = 'suppliers-classification',
  MARKETPLACE = 'marketplace'
}

export const FreePackageOptions: Readonly<PackageOptions[]> = [
  PackageOptions.REQUESTS_REGULAR_CREATE,
  PackageOptions.RESPONSES_COMPARE,
  PackageOptions.MESSENGER
] as const;

interface LastMessage {
  type: string;
  id: string;
  time: string;
  whoEmployeeId: Id;
  whoName: string;
  whomEmployeeId: Id;
  whomName: string;
}

export interface Chat {
  type: string;
  id: string;
  name: string;
  color: string;
  requestNumber?: string;
  requestCreatedAt?: string;
  procurementInfo?: {
    id: string;
    customId: string;
    publishedAt: string;
    name: string;
  };
  ownerEmployeeId: number;
  hardcodeType?: string;
  lastMessage: LastMessage;
  members: ChatMember[];
  unreadMessagesId: Id[];
  notificationOffMembers: any[];
  scheduledMessages: any[];
  confirm: any[];
  pinnedMessages: any[];
  createdAt: string;
  checkedLists: {
    system: string[];
    user: any[];
  };
}

export interface VendorsEvaluationPollsBadge {
  type: string;
  status: string;
  typeId: number;
  userId: number;
  companyId: number;
}

export interface InviteInfo {
  email: string;
  companyName: string;
  type: string;
  companyBranch: string;
  roles: {
    customer: boolean;
    supplier: boolean;
  };
  isUploaded: boolean;
}
