import { Routes, Route } from 'react-router-dom';
import { ProtectedRoute } from '@/components/PrivateRoute';
import { useAppSections } from '@/hooks/useAppSections';
import { SettingsPage } from '@/pages/settings';
import { RoleManagement } from '@/pages/RoleManagement';
import { NotificationsPage } from '@/pages/messenger/notifications';
import { ProcurementManagement } from '@/pages/procurementManagement';
import { ProcurementManagementScripts } from '@/pages/procurementManagementScripts';
import { ProcurementManagementFormSettings } from '@/pages/procurementManagementFormSettings';
import { ProposalApprovalScrips } from '@/pages/proposalApprovalScripts';
import { NeedsFormSettings } from '@/pages/needFormSettings';

export function SettingsRouter() {
  const { sections } = useAppSections();

  return (
    <Routes>
      <Route
        path="/general"
        element={
          <ProtectedRoute isAllowed={sections.settingsGeneral} redirectPath="/">
            <SettingsPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/notifications"
        element={
          <ProtectedRoute
            isAllowed={sections.settingsNotifications}
            redirectPath="/">
            <NotificationsPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/role-management/*"
        element={
          <ProtectedRoute
            isAllowed={sections.companyRoleManagement}
            redirectPath="/">
            <RoleManagement />
          </ProtectedRoute>
        }
      />
      <Route
        path="/procurement-management"
        element={
          <ProtectedRoute
            isAllowed={sections.procurementManagement}
            redirectPath="/">
            <ProcurementManagement />
          </ProtectedRoute>
        }
      />
      <Route
        path="/procurement-management/scripts/procurements"
        element={
          <ProtectedRoute
            isAllowed={sections.procurementManagementScrips}
            redirectPath="/">
            <ProcurementManagementScripts />
          </ProtectedRoute>
        }
      />
      <Route
        path="/procurement-management/scripts/proposal-approval"
        element={
          <ProtectedRoute
            isAllowed={sections.proposalApprovalScripts}
            redirectPath="/">
            <ProposalApprovalScrips />
          </ProtectedRoute>
        }
      />
      <Route
        path="/procurement-management/form-settings"
        element={
          <ProtectedRoute
            isAllowed={sections.procurementManagementFormSettings}
            redirectPath="/">
            <ProcurementManagementFormSettings />
          </ProtectedRoute>
        }
      />
      <Route
        path="/needs/form-settings"
        element={
          <ProtectedRoute
            isAllowed={sections.needsFormManagement}
            redirectPath="/">
            <NeedsFormSettings />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
}
