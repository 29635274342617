import { useCallback, ChangeEvent, memo } from 'react';
import cn from 'classnames';
import { useId } from '@/hooks';
import { noop } from '@/lib/utils';
import { Typography } from '@/components/Typography';
import { Tooltip } from '@/components/Tooltip';
import { Icon } from '@/components/Icon';
import { InputCounterProps } from './InputCounter.types';
import classes from './InputCounter.module.scss';

export const InputCounter = memo(
  ({
    id: idOverride,
    name,
    className,
    value = '',
    onChange,
    onInputChange,
    placeholder,
    label,
    total,
    typography,
    disabled,
    valid = true,
    required = false,
    errorText,
    onBlur = noop,
    definition,
    pattern,
    upperCase = false
  }: InputCounterProps) => {
    const id = useId(idOverride);

    const onChangeCallback = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        let newValue = e.target.value;

        if (upperCase) {
          newValue = newValue.toUpperCase();
        }

        if (pattern && !pattern.test(newValue)) return;

        if (newValue.length <= total) {
          if (onChange) {
            onChange(newValue);
          }
          if (onInputChange) {
            onInputChange(e);
          }
        }
      },
      [onChange, total, onInputChange, upperCase]
    );

    return (
      <div className={className} data-testid="input-counter">
        <label htmlFor={id} className={classes.label}>
          <Typography
            className={cn(
              classes.labelContent,
              Boolean(label) && classes.withLabel
            )}
            variant={typography}
            noWrap>
            {label && (
              <div className={classes.labelTextContainer}>
                <span className={cn({ [classes.required]: required })}>
                  {label}
                </span>
                {definition && (
                  <Tooltip title={definition} maxWidth={240} align="left">
                    <Icon iconName="question-fill" width={12} height={12} />
                  </Tooltip>
                )}
              </div>
            )}
            <div>{`${value ? value.length : '0'}/${total}`}</div>
          </Typography>
        </label>
        <input
          className={cn(classes.input, !valid && classes.isError)}
          id={id}
          name={name}
          value={value}
          onChange={onChangeCallback}
          placeholder={placeholder}
          disabled={disabled}
          onBlur={onBlur}
        />
        {!valid && errorText && (
          <Typography variant="caption" className={classes.errorMessage}>
            {errorText}
          </Typography>
        )}
      </div>
    );
  }
);

InputCounter.displayName = 'InputCounter';
