import React, { Component, Suspense } from 'react';
import { monitoringService } from '@/api';
import PageLoader from '@/components/Loader/PageLoader';
import { RefreshTheApp } from '@/pages/refreshTheApp';
import appConfig from '@/config/app';
import { RobotPage } from '@/pages/robotPage';

const isVersionOutdated = (error) => {
  const errorCode = 'CSS_CHUNK_LOAD_FAILED';

  if (error instanceof Error) {
    return error.name === 'ChunkLoadError' || error.code === errorCode;
  }
  return false;
};

class CatchErrors extends Component {
  #monitoringService;

  constructor(props) {
    super(props);
    this.state = { error: null };
    this.isCatchedErrorRef = React.createRef(false);
    this.#monitoringService = monitoringService;
  }

  #needSendReport(error) {
    if (isVersionOutdated(error)) return false;

    return !(error.status && error.status < 500);
  }

  componentDidCatch(error, errorInfo) {
    if (this.isCatchedErrorRef.current) return;

    this.isCatchedErrorRef.current = true;
    this.setState({ error });

    if (this.#needSendReport(error)) {
      const report = {
        message: error.message,
        stack: error.stack,
        appVersion: appConfig.version,
        errorInfo: {
          ...errorInfo,
          pageUrl: window.location.href
        }
      };
      this.#monitoringService.sendReport(report);
    }
  }

  render() {
    const { error } = this.state;
    const { children } = this.props;

    const render = () => {
      if (error) {
        this.isCatchedErrorRef.current = false;
        if (isVersionOutdated(error)) {
          return <RefreshTheApp />;
        }
        return <RobotPage />;
      }

      return children;
    };

    return <Suspense fallback={<PageLoader />}>{render()}</Suspense>;
  }
}

export default CatchErrors;
