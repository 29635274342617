import React from 'react';
import cn from 'classnames';
import { Tappable, TappableProps } from '@/components/Tappable';
import { Typography } from '@/components/Typography';
import { WithClassName } from '@/components/common.types';
import { IconName, Icon } from '@/components/Icon';
import styles from './Button.module.css';

interface IconAdornment extends WithClassName {
  type: 'icon';
  name: IconName;
}

type AdornmentType = IconAdornment | React.ReactNode;

interface AdornmentProps {
  adornment: AdornmentType;
}

export interface UIButtonProps {
  variant?: 'fill' | 'text';
  mode?: 'primary' | 'secondary' | 'text';
  size?: 'small' | 'large';
  stretched?: boolean;
  startAdornment?: AdornmentType | null;
  endAdornment?: AdornmentType | null;
  loading?: boolean;
  contentStyles?: string;
}

export interface ButtonProps
  extends Omit<TappableProps, 'size'>,
    UIButtonProps {}

interface ButtonTypographyProps {
  size: ButtonProps['size'];
  className?: string;
  children?: ButtonProps['children'];
}

function isIconAdornment(adornment: AdornmentType): adornment is IconAdornment {
  return (
    typeof adornment === 'object' &&
    adornment !== null &&
    'type' in adornment &&
    adornment.type === 'icon'
  );
}

function ButtonTypography({ size, ...restProps }: ButtonTypographyProps) {
  switch (size) {
    case 'small':
      return <Typography variant="body2Reg" {...restProps} />;
    default:
      return <Typography variant="body1Reg" {...restProps} />;
  }
}

function Adornment(props: AdornmentProps) {
  const { adornment } = props;

  if (isIconAdornment(adornment)) {
    const { name, className } = adornment;

    return <Icon className={cn(styles.icon, className)} iconName={name} />;
  }

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{adornment}</>
  );
}

export function Button({
  variant = 'fill',
  size = 'large',
  mode = 'primary',
  stretched = false,
  children,
  startAdornment,
  endAdornment,
  getRootRef,
  Component = 'button',
  loading,
  onClick,
  stopPropagation = true,
  contentStyles,
  ...restProps
}: ButtonProps) {
  const hasIconOnly =
    !children && Boolean(endAdornment) !== Boolean(startAdornment);

  return (
    <Tappable
      data-testid="button"
      hoverMode={styles.hover}
      activeMode={styles.active}
      {...restProps}
      Component={restProps.href ? 'a' : Component}
      onClick={loading ? undefined : onClick}
      focusVisibleMode="outside"
      stopPropagation={stopPropagation}
      className={cn(
        restProps.className,
        styles.button,
        styles[`button-variant-${variant}`],
        styles[`button-mode-${mode}`],
        styles[`button-size-${size}`],
        stretched && styles.stretched,
        loading && styles.loading,
        hasIconOnly && styles['single-icon'],
        !children && styles.iconOnly
      )}
      getRootRef={getRootRef}>
      <div className={styles.in}>
        {startAdornment && <Adornment adornment={startAdornment} />}
        {children && (
          <ButtonTypography
            size={size}
            className={cn(styles.content, contentStyles)}>
            {children}
          </ButtonTypography>
        )}
        {endAdornment && <Adornment adornment={endAdornment} />}
      </div>
    </Tappable>
  );
}
