import {
  useState,
  useRef,
  useEffect,
  useCallback,
  createContext,
  useMemo,
  useContext,
  ReactNode,
  CSSProperties,
  memo
} from 'react';
import InfiniteLoader from 'react-window-infinite-loader';
import AutoSizer from 'react-virtualized-auto-sizer';
import { Loader } from '@/components/Loader';
import { VariableSizeList as List } from 'react-window';
import cn from 'classnames';
import useResizeObserver from 'use-resize-observer';
import { isFunction } from '@/lib/utils';
import { DynamicListProps, UpdateItem, RemoveItem } from './DynamicList.types';
import styles from './DynamicList.module.scss';

const DEFAULT_BUTCH_SIZE = 20;
const DEFAULT_ITEM_WIDTH = 100;
const THRESHOLD_MULTIPLIER = 1.3;

interface ItemProps<TEntity> {
  index: number;
  style: CSSProperties;
  data: TEntity[];
}

interface KeyValuePair<TValue> {
  [key: string]: TValue;
}

interface Context {
  updateItem: <TEntity>(index: number) => UpdateItem<TEntity>;
  removeItem: (index: number) => RemoveItem;
  renderItem: <TEntity>(
    item: TEntity,
    updateItem: UpdateItem<TEntity>,
    remove: RemoveItem
  ) => ReactNode;
  isItemLoaded: (index: number) => boolean;
  setSize: (index: number, size: number) => void;
  gap: number;
  divider: ReactNode | null;
}

interface ResizeCallback {
  height?: number;
}

const DynamicListContext = createContext(null as unknown as Context);

function ItemComponent<TEntity>(props: ItemProps<TEntity>) {
  const { index, style, data } = props;

  const {
    updateItem,
    removeItem,
    renderItem,
    isItemLoaded,
    setSize,
    gap,
    divider
  } = useContext(DynamicListContext);
  const root = useRef<HTMLDivElement | null>(null);

  const currentItem = data[index];

  useEffect(() => {
    if (root.current) {
      setSize(index, root.current.getBoundingClientRect().height + gap);
    }
  }, [root, index, setSize, gap, currentItem]);

  const setCurrentItem = useMemo(() => updateItem(index), [index, updateItem]);
  const setRemovedItem = useMemo(() => removeItem(index), [index, removeItem]);

  const onResize = useCallback(
    ({ height }: ResizeCallback) => {
      if (height) {
        setSize(index, height + gap);
      }
    },
    [setSize, index, gap]
  );

  useResizeObserver({ ref: root, onResize });

  if (!isItemLoaded(index)) {
    return null;
  }

  if (currentItem == null) {
    return null;
  }
  return (
    <div key={index} style={style}>
      <div ref={root}>
        {index !== 0 ? divider : null}
        {renderItem(currentItem, setCurrentItem, setRemovedItem)}
      </div>
    </div>
  );
}

const Item = memo(ItemComponent) as typeof ItemComponent;

export function DynamicList<TEntity>({
  provider,
  renderItem,
  batchSize = DEFAULT_BUTCH_SIZE,
  noResultsScreen,
  className = '',
  gap = 8,
  header,
  reset,
  divider = null,
  onLoad,
  noResultsHeader = false
}: DynamicListProps<TEntity>) {
  const threshold = batchSize * THRESHOLD_MULTIPLIER;

  const listRef = useRef<List | null>(null);

  const headerRef = useRef<HTMLDivElement>(null);

  function copyRef(node: List<any> | null, cb: (ref: any) => void) {
    listRef.current = node;
    cb(node);
  }

  const [hasNextPage, setHasNextPage] = useState(true);
  const [items, setItems] = useState<TEntity[]>([]);
  const [totalItems, setTotalItems] = useState(batchSize);
  const [showLoader, setShowLoader] = useState(true);

  const [isReloadNeeded, setIsReloadNeeded] = useState(false);

  const sizeMap = useRef<KeyValuePair<number>>({});

  const setSize = useCallback((index: number, size: number) => {
    sizeMap.current = { ...sizeMap.current, [index]: size };
    if (listRef && listRef.current) {
      listRef.current.resetAfterIndex(index);
    }
  }, []);

  const getSize = useCallback(
    (index: number) => sizeMap.current[index] || DEFAULT_ITEM_WIDTH,
    []
  );

  const loadData = useCallback(
    async (startIndex: number, _endIndex: number) => {
      const data = await new Promise<TEntity[]>((resolve) => {
        provider
          .load(startIndex / batchSize + 1, batchSize)
          .then((res) => resolve(res))
          .catch(() => {
            provider.onError?.();
            resolve([]);
          });
      });

      setItems((prev) => {
        setShowLoader(!prev.length);
        if (data.length < batchSize) {
          setTotalItems(prev.length + data.length);
          setHasNextPage(false);
          if (isFunction(provider.loaded)) {
            provider.loaded(prev.length + data.length);
          }
        } else {
          setTotalItems((prevTotal) => prevTotal + batchSize);
          setHasNextPage(true);
        }
        setShowLoader(false);
        return [...prev, ...data];
      });
    },
    [batchSize, provider]
  );

  const infiniteLoaderLoadDataHandler = useCallback(
    async (startIndex: number, endIndex: number) => {
      if (startIndex === 0) {
        return;
      }
      await loadData(startIndex, endIndex);
    },
    [loadData]
  );

  useEffect(() => {
    setItems([]);
    sizeMap.current = {};
    setHasNextPage(true);
    setTotalItems(batchSize);
    setShowLoader(true);
    setIsReloadNeeded(true);
  }, [provider, batchSize, reset]);

  useEffect(() => {
    async function reload() {
      await loadData(0, 0);
    }

    if (isReloadNeeded) {
      reload()
        .catch((e) => console.log('Cannot reload data, error = ', e))
        .finally(() => setIsReloadNeeded(false));
    }
  }, [isReloadNeeded]);

  useEffect(() => {
    if (isFunction(onLoad)) {
      onLoad(totalItems);
    }
  }, [totalItems, onLoad]);

  const isItemLoaded = useCallback(
    (index: number) => !hasNextPage || index < items.length,
    [hasNextPage, items.length]
  );

  const updateItem = useCallback(
    (index: number) => (payload: Partial<TEntity>) => {
      setItems((prevItems) =>
        prevItems.map((current, currentIndex) =>
          currentIndex === index ? { ...current, ...payload } : current
        )
      );
    },
    []
  );

  const removeItem = useCallback(
    (index: number) => () => {
      setItems((prevItems) =>
        prevItems.filter((_current, currentIndex) => currentIndex !== index)
      );
    },
    []
  );

  // @ts-ignore
  const context: Context = useMemo(
    () => ({
      renderItem,
      setSize,
      isItemLoaded,
      gap,
      divider,
      updateItem,
      removeItem
    }),
    [setSize, isItemLoaded, gap, renderItem, divider, updateItem, removeItem]
  );

  return (
    <div className={cn(className, styles.dynamicList)}>
      {showLoader && <Loader />}
      {totalItems === 0 ? (
        !showLoader && (
          <>
            {noResultsHeader && header} {noResultsScreen}
          </>
        )
      ) : (
        <>
          {!showLoader && header && <div ref={headerRef}>{header}</div>}
          <DynamicListContext.Provider value={context}>
            <AutoSizer
              className={cn(styles.list, showLoader && styles.loading)}>
              {({ height, width }) => {
                const headerHeight = headerRef.current?.clientHeight || 0;
                const listHeight = height - headerHeight;

                return (
                  <InfiniteLoader
                    isItemLoaded={isItemLoaded}
                    itemCount={totalItems}
                    loadMoreItems={infiniteLoaderLoadDataHandler}
                    minimumBatchSize={batchSize}
                    threshold={threshold}>
                    {({ onItemsRendered, ref }) => (
                      <List
                        height={listHeight}
                        itemCount={totalItems}
                        width={width}
                        itemSize={getSize}
                        itemData={items}
                        onItemsRendered={onItemsRendered}
                        ref={(node) => copyRef(node, ref)}>
                        {/* Avoid passing custom props, because it called remount component each time (https://github.com/bvaughn/react-window/issues/413), use context instead */}
                        {Item}
                      </List>
                    )}
                  </InfiniteLoader>
                );
              }}
            </AutoSizer>
          </DynamicListContext.Provider>
        </>
      )}
    </div>
  );
}
