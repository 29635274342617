import { PREFIX } from '../ChatWidget/modules/constants';

export const REDUX_KEY = 'navigationSidebar';

export const NAV_PANEL_LS_KEY = 'messenger-navigation-sidebar';

export const SET_FULL_MODE = `${PREFIX}:SET_MODE:FULL`;
export const SET_SHORT_MODE = `${PREFIX}:SET_MODE:SHORT`;
export const TOGGLE_MODE = `${PREFIX}:TOGGLE_MODE`;

export enum PaidSection {
  Needs = 'needs:edit',
  AmoNeeds = 'plugin:amo',
  Routes = 'routes:edit',
  Accreditation = 'accreditation:edit',
  Qualification = 'qualification:edit',
  VendorsEvaluation = 'vendors-evaluation'
}
