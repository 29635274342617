import { ProposalPositionType } from '@/shared/constants/proposalPosition';
import { toNumber } from '@/utils/toNumber';

export class ProductDto {
  constructor(props) {
    this.id = props.id;
    this.name = props.name;
    this.originalName = props.originalName;
    this.unit = props.unit;
    this.count = toNumber(props.count, 0);
    this.price = +props.price;
    if (props.type !== ProposalPositionType.ROOT) {
      this.tax = props.tax;
    }
    if (ProposalPositionType.COMPONENT === props.type) {
      this.rootId = props.rootId;
    }
    if (
      [ProposalPositionType.COMPONENT, ProposalPositionType.ROOT].includes(
        props.type
      )
    ) {
      this.type = props.type;
    }
  }
}
