interface ForeignCompanyInfoPayload {
  countryCode: string;
  ino: string;
  name: string;
}

export class ForeignCompanyInfoDto {
  public readonly countryCode: string;

  public readonly ino: string;

  public name: string;

  constructor(props: ForeignCompanyInfoPayload) {
    this.countryCode = props.countryCode;
    this.ino = props.ino;
    this.name = props.name;
  }
}
