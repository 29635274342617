import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Main } from '@/pages/administration/main';
import { usePermissions } from '@/modules/permissions';
import { ProtectedRoute } from '@/components/PrivateRoute';
import { AdministrationSettings } from '@/pages/administration/settings';
import { AdministrationCompaniesPage } from '@/pages/administration/companies';
import { AdministrationCompanyPage } from '@/pages/administration/company';
import { AdministrationRequestPage } from '@/pages/administration/request';
import { ProposalEdit } from '@/pages/administration/proposalEdit';
import { Users } from '@/pages/administration/users';
import { InboxList } from '@/pages/administration/InboxList';
import { InboxPage } from '@/pages/administration/inbox';
import { RequestsUnderModeration } from '@/pages/administration/requestsUnderModeration';
import { AdministrationProcurementsPage } from '@/pages/administration/procurements';

export const AdministrationRouter = () => {
  const permissions = usePermissions();
  const canViewAdministrationPanel =
    permissions.haveAdministrationReadPermissions();
  const haveAdministrationEditPermissions =
    permissions.haveAdministrationEditPermissions();

  if (!canViewAdministrationPanel) return <Navigate to="/no-access" replace />;

  return (
    <Routes>
      <Route path="/" element={<Main />} />
      <Route path="/companies" element={<AdministrationCompaniesPage />} />
      <Route
        path="/companies/:companyId"
        element={<AdministrationCompanyPage />}
      />
      <Route
        path="/companies/:companyId/requests/:requestId"
        element={<AdministrationRequestPage />}
      />
      <Route path="/users" element={<Users />} />
      <Route
        path="/settings"
        element={
          <ProtectedRoute isAllowed={haveAdministrationEditPermissions}>
            <AdministrationSettings />
          </ProtectedRoute>
        }
      />
      <Route
        path="/procurements"
        element={<AdministrationProcurementsPage />}
      />
      <Route path="/inbox/:messageId" element={<InboxPage />} />
      <Route
        path="/inbox"
        element={
          <ProtectedRoute isAllowed={haveAdministrationEditPermissions}>
            <InboxList />
          </ProtectedRoute>
        }
      />
      <Route
        path="/inbox/:messageId/manage-proposal"
        element={<ProposalEdit />}
      />
      <Route path="/moderation" element={<RequestsUnderModeration />} />
    </Routes>
  );
};
