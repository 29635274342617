import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { getOpenedChat } from '@/components/ChatWidget/getter';
import { joinChat, uploadMessages } from '@/components/ChatWidget/useCases';
import { reloadChatList } from '@/components/ChatWidget/modules/chats/useCases';
import { getAllBadges } from '@/components/ChatWidget/modules/badges';

type State = unknown;

export function restoreChatConnection(): ThunkAction<
  void,
  State,
  unknown,
  Action<string>
> {
  return async (dispatch, getState) => {
    const state = getState();

    const openedChat = getOpenedChat(state);

    if (openedChat) {
      joinChat(openedChat.id);
      await dispatch(uploadMessages(openedChat));
      await dispatch(reloadChatList());
      await dispatch(getAllBadges());
    }
  };
}
